.cover1 {
    background-color: rgb(230, 231, 231);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%; 
    height: auto;
    position: relative;
    border-radius: 1em;
    box-shadow: 0 0.080em 0.800em rgb(100, 156green, 156blue);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    display: flex;
    margin-left: auto; 
    margin-right: 11em; 
    margin-top: 11em;
  }
  .title3 {
    margin-top: 20px;
    margin-right: 140px;
    margin-left: 180px;
    font-size: 1.5em;
  }
  .images-container {
    position: absolute;
    left: 70px;
    top: 50px;
    margin-top: 150px;
  }
  
  .animated-image {
    position: absolute;
    left: 40px;
    top: 0;
    width: 120%;
  }
  
  .input-container {
    display: flex;
    margin-bottom: 10px;
    
  }
  
  .input-container input {
    width: 200px;
    height: 30px;
    margin-right: 10px;
  }

  .select-gender{
    width: 200px;
    height: 55px;
    margin-right: 10px;
    border: 1px solid rgb(223, 223, 223);
  }

  .ville{
    margin-right: 20px;
    border: 1px solid rgb(223, 223, 223);
  }


  .code-postale{
    border: 1px solid rgb(223, 223, 223);
  }

  .bday{
    border: 1px solid rgb(223, 223, 223);
  }
  
  
  .adresse-user{
    width: 410px;
    margin-right: 10px;
    border: 1px solid rgb(223, 223, 223);
  }
  
  .condition{
    width: 25px;
    height: 25px;
    margin-top: 5px;
    margin-right: 360px;

  }

  .label-condition{
    margin-top: -35px;
    margin-right: -20px;

  }

  .login-btn1 {
  
    background-color: #2196F3;
    color: #FFFFFF;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 200px;
    height: 40px;
    margin-top: 20px;
    margin-left: 200px;
    margin-bottom: 30px;
  }

  .login-btn1:hover{
    transform: scale(1.2);
  }

  .erreur{
    color: red;
  }
  

  