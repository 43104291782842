.titlecolis{

    font-size: 24px;
    margin-left: 200px;

}

.search-input-container-colis{

    margin-left: 150px;

}

.error-message-colis{
color: red;
font-size: 15px;
margin-top: -5px;
}
