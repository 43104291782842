.home-container {
    font-size: 28px;
    color: #130C4D;
    font-weight: bold;
  }
  .subtitle1 {
    font-size: 20px;
    color: #130C4D;
    padding-left: 20px;
    position: relative;
    top: 50px; 
    left: -300px;
  }
  .subtitle2 {
    font-size: 16px;
    color: #3787c9;
    padding-left: 20px;
    position: relative;
    top: 50px; 
    left: -300px;
    display: inline-block;
    width: 300px;
  }
  
  .boutton {
    
    background-color: #2196F3;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: block;
    margin-top: 70px;
    margin-left: 390px;

  }
  
  .boutton:hover {
    background-color: #0D47A1;
    transform: scale(1.2);
  }