.etape6 {

  margin-bottom: 350px;
}

.container6 {
    margin-top: 60px;
  }
  
  .label4 {
    margin-top: 40px;
    font-size: 24px;
    text-align: center;
    color: #0D47A1;
    
  }
  
  .passenger-counter {
    display: flex;
    align-items: center;
    margin-left: 890px;
    
  }
  
  .counter-button {
    margin-right: 5px;
    margin-top: 30px;
  }
  
  .counter-value {
    margin: 0 8px;
    margin-top: 30px;
  }
  .container5{
    margin-top: 40px;
  }
  .label5 {
    margin-top: 40px;
    font-size: 24px;
    text-align: center;
    color: #0D47A1;
    
  }


  .horizontal-line7{
    width: 600px; 
    border: none;
    border-top: 2.5px solid cornflowerblue; 
    margin: 10px 0; 
    margin-left: 620px;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  
  .icon1-section {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-left: 800px;
  }
  
  .icone {
    width: 24px;
    height: 24px;
    margin-right: 20px;
  }
  
  .eq1 {
    margin-right: 10px;
    white-space: nowrap;
    color: #130C4D;
  }
  
  .checkboxl1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 20px;
    border: 1px solid #ccc;
    overflow: hidden;
    margin-left: 55px;
    margin-top: 15px;
  }
  
  .checkbox label {
    display: block;
    width: 18px;
    height: 18px;
  }
  
  .checkbox label::after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #ccc;
    transition: background-color 0.3s ease;
  }
  
  .checkbox input[type="checkbox"]:checked + label::after {
    background-color: red;
  }
  
  .icone2-section {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-left: 500px;

  }
  
  .icone2 {
    width: 24px;
    height: 24px;
    margin-left: -50px;
    margin-top: 10px;
  } 

  .eq2 {
    margin-left: 0px;
    white-space: nowrap;
    color: #130C4D;
  }

  .checkboxl2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 20px;
    border: 1px solid #ccc;
    overflow: hidden;
    margin-left: 1035px;
    margin-top: -15px;
  }
  
  .checkboxl2 label {
    display: block;
    width: 18px;
    height: 18px;
  }
  
  .checkboxl2 label::after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #ccc;
    transition: background-color 0.3s ease;
  }
  
  .checkboxl2 input[type="checkbox"]:checked + label::after {
    background-color: red;
  }

  .icone3-section {
    display: flex;
    align-items: center;
    margin-top: -200px;
    margin-left: -80px;
  }
  
  .icone3 {
    width: 24px;
    height: 24px;
    margin-left: -100px;
    margin-top: 10px;
    
  } 

  .eq3 {
    margin-left: 0px;
    white-space: nowrap;
    color: #130C4D;
  }

  .checkboxl3 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 20px;
    border: 1px solid #ccc;
    overflow: hidden;
    margin-left: 1035px;
    margin-top: -15px;
  }
  
  .checkboxl3 label {
    display: block;
    width: 18px;
    height: 18px;
  }
  
  .checkboxl3 label::after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #ccc;
    transition: background-color 0.3s ease;
  }
  
  .checkboxl3 input[type="checkbox"]:checked + label::after {
    background-color: red;
  }

  .icone3-section {
    display: flex;
    align-items: center;
    margin-top: -160px;
    margin-left: -80px;
  }
  
  .icone4 {
    width: 24px;
    height: 24px;
    margin-left: -100px;
    margin-top: 10px;
    
  } 

  .eq4 {
    margin-left: 0px;
    white-space: nowrap;
    color: #130C4D;
  }

  .checkboxl4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 20px;
    border: 1px solid #ccc;
    overflow: hidden;
    margin-left: 1035px;
    margin-top: -15px;
  }
  
  .checkboxl4 label {
    display: block;
    width: 18px;
    height: 18px;
  }
  
  .checkboxl4 label::after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #ccc;
    transition: background-color 0.3s ease;
  }
  
  .checkboxl4 input[type="checkbox"]:checked + label::after {
    background-color: red;
  }


  .icone5 {
    width: 24px;
    height: 24px;
    margin-left: -80px;
    margin-top: 10px;
    
  } 

  .eq5 {
    margin-left: 0px;
    white-space: nowrap;
    color: #130C4D;
  }

  .checkboxl5 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 20px;
    border: 1px solid #ccc;
    overflow: hidden;
    margin-left: 1035px;
    margin-top: -15px;
  }
  
  .checkboxl5 label {
    display: block;
    width: 18px;
    height: 18px;
  }
  
  .checkboxl5 label::after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #ccc;
    transition: background-color 0.3s ease;
  }
  
  .checkboxl5 input[type="checkbox"]:checked + label::after {
    background-color: red;
  }

  .tocontinuate2 {
    background-color: #2196F3;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 50px;
    margin-left: 40px;
  } 
  .tocontinuate2:hover {
    background-color: #1565c0;
  } 

  .comeback6{
    width: 40px;
    height: 40px;
    color: cornflowerblue;
    margin-top: 20px;
    margin-left: -1460px;

  }

  