.etape {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
  }
  
  .step1-label {
    font-size: 24px;
    text-align: center;
    color: #0D47A1;
  }
  .comeback2{
    width: 40px;
    height: 40px;
    color: cornflowerblue;
    margin-top: 20px;
    margin-left: -960px;

  }
  .input-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
  
  .input {
    font-size: 15px;
    width: 600px;
    height: 40px;
    margin-top: 30px;
    padding-left: 40px;
    background-image: url('./search.png');
    background-repeat: no-repeat;
    background-position: 10px center;
    background-color: #f1f1f1;
    background-size: 35px;
    border: 1px solid #928e8e; /* Couleur grise de la bordure */
    border-radius: 12px; /* Rayon de bordure de 8 pixels */
  }
  .submit-button {
    background-color: #2196F3;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 800px;
    margin-bottom: 300px;
  }
  
  .submit-button:hover {
    background-color: #1565c0;
  } 

 

/* Ajoutez les styles pour le dialogue-box modale */
.dialog-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Couleur d'arrière-plan semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Assurez-vous que la boîte de dialogue est au-dessus du contenu de la page */
}

.dialog-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  height: 240px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dialog-box:not(.open) {
  display: none;
}


  .fermer {
    background-color: #2196F3;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 60px;
  } 

  .adrmap2{
    margin-top: -90px;
    margin-left: 220px;
  }