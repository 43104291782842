  .edit {
    background-color: #ffffff; 
    width: 600px;
    height: 50px;
    margin-left: 650px;
    color: #333; 
    border: none;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    padding: 20px 40px; 
    font-size: 18px; 
    cursor: pointer; 
  }

  .bloc1{
    margin-top: -100px;
  }

  .row {
    display: flex;
  }
  
  .row > div {
    margin-right: 0px; 
  }
  
  
  .adrhr2,
  .adrde2,
  .adrarv2 {
    flex-direction: column;
    margin-left: 800px;
    
  }
  
  
  .delete {
    background-color: #ffffff; 
    width: 600px;
    height: 50px;
    margin-left: 650px;
    color: #333; 
    border: none;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    padding: 20px 40px; 
    font-size: 18px; 
    cursor: pointer; 
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }

  .customColor {
    color:#130C4D;
  }
  
  .edit:hover {
    background-color: #ddd; 
  }

  .trip-horizontal-line1{
    width: 600px; 
    border: none;
    border-top: 2.5px solid cornflowerblue; 
    margin: 10px 0; 
    margin-left: 650px;
    margin-top: 30px;
  }
  .trip-horizontal-line2{
    width: 600px; 
    border: none;
    border-top: 2.5px solid cornflowerblue; 
    margin: 10px 0; 
    margin-left: 650px;
    margin-top: 30px;
  }
  
  .trip-horizontal-line3{
    width: 600px; 
    border: none;
    border-top: 2.5px solid cornflowerblue; 
    margin: 10px 0; 
    margin-left: 650px;
    margin-top: 10px;
  }

  .modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 69px;
  border-radius: 4px;
  color: #0D47A1;
}

.modal p {
  margin-bottom: 10px;
}

.modal button {
  margin-right: 10px;
}

.butt1 {
  background-color:  #28a745;
  color: #FFFFFF;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
}

.butt2 {
  background-color: #dc3545;
  color: #FFFFFF;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
}

