
.comeback1{
    width: 40px;
    height: 40px;
    color: cornflowerblue;
    margin-top: 40px;
    margin-left: -960px;

  }

  .edit {
   color: #130C4D;

  }


  .edit-the-trip {
    color: #130C4D;
    margin-top: 30px;
   }
  
  .edit-icon1{
    height: 40px;
    width: 40px;
    margin-right: 50px;
  }
  
  .edit-icon2{
    height: 40px;
    width: 40px;
    margin-right: 90px;
  }
  .edit-icon3{
    height: 40px;
    width: 40px;
    margin-right: 120px;
  }
  
  .edit-icon4{
    height: 40px;
    width: 40px;
    margin-right: 40px;
  }
  
  .edit-icon5{
    height: 40px;
    width: 40px;
    margin-right: 40px;
  }
  
  .editrip-label{
    font-size: 24px;
    text-align: center;
    color: #0D47A1;
    margin-top: 20px;
  } 

  .edittrip-horizontal-line {
    border: none;
    border-top: 1px solid #ddd;
    margin: 10px 0;
    width: 600px;
    margin-left:650px ;
  }
  