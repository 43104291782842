.footer {
  border-top: solid 2px rgb(184, 180, 180) ;
  height: 100px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}


.footer-content {
  display: flex;
  justify-content: flex-end;
}
.footer-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer-content li {
  display: inline-block;
  margin: 0 10px;
  color: red;
}
.footer-content li a {
  color: #130C4D;
}

.footer-content li a :hover{
transform: scale(1.2);
}

.footer img {
  width: 32px; 
  height: 32px; 
  margin-left: 10px;
  margin-top: 22px;
}

.footer img:hover{
  transform: scale(1.2);
}
