/* addvehicule.css */
.Addvehicule-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}

.image-container1 {
  flex: 0 0 auto;
  margin-right: 20px;
  margin-left: -700px;
}

.car-image {
  width: 200px;
  height: auto;
}

.form-container {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  margin-bottom: 20px;
  width: 300px;
  text-align: center;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.Addcar-label {
  font-size: 24px;
  text-align: center;
  color: #0D47A1;
  margin-top: 30px;
}

label.red-text {
  color: #0056b3;
  text-align: left;
  margin-left: 10px;
}

.form-control,
select {
  width: 400px;
  height: 40px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 15px;
  background-color: #fff;
  color: #333;
}

.form-control {
  border-color: #ccc;
}

select {
  cursor: pointer;
}

.form-control::placeholder {
  color: #797979;
}

.form-control.blue-input {
  border-color: #007bff;
}

.form-control.blue-input::placeholder {
  color: #007bff;
}

.btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  margin-left: 200px;
  border-radius: 15px;
  cursor: pointer;
}

.btn:hover {
  background-color: #0056b3;
}

.invalid-feedback {
  color: red;
  font-size: 14px;
}

.scrollable-content {
  max-height: auto;
  overflow-y: auto;
  margin-top: -450px;
}

.comeback-car{
  width: 40px;
  height: 40px;
  color: cornflowerblue;
  margin-top: 20px;
  margin-left: -1460px;

}

.new-background{
margin-left: -800px;
margin-top: 200px;
}

