
.comeback3{
    width: 40px;
    height: 40px;
    color: cornflowerblue;
    margin-top: 20px;
    margin-left: -960px;

  }

   
  .step2-label {
    font-size: 24px;
    text-align: center;
    color: #0D47A1;
  }

  .map-label {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 255, 255, 0.8);
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    margin-top: 270px;
  }
  