.resultat1 {
    display: flex;
    color: #130C4D;
    align-items: center;
    font-size: 1.5em;
    margin-bottom: 10px;
    white-space: nowrap;
   }

   .result-container{
    color:#130C4D;
    margin-top: 70px;
    margin-left: -400px;
   }

   .mytrip-list{
   color:#130C4D;
   margin-top: -700px;
   margin-left: 400px;
   position: sticky;
   top: 0;
   z-index: 1;
  }

   .triangle-ListTripsContainer {
    width: 530px;
    height: 200px;
    background-color: #FFFFFF;
    border: 1px solid #ccc;
    border-radius: 12px;
    margin-top: 30px;
    margin-left: 500px;
  }
  
  .colisIcon{
  height: 38px;
  width: 38px;
 }


  .triangle-listtrips {
    margin-left: -200px;
    width: 850px;
    height: 250px;
  }
  
  .departarriv1{    
    padding: 10px;
    margin-left: 5px;
    color: #130C4D;
    font-size: 18px;
  }

  .leprix{    
    padding: 10px;
    margin-left: 560px;
    color: #130C4D;
    margin-top: -38px;
    font-size: 18px;
  }

  .conducteur1 {
    display: flex;
    align-items: center;
    margin-left: 240px;
    color: #130C4D;
    margin-top: 58px;
  }
  
  .profilicone1{
    width: 24px; 
    height: 24px; 
    margin-right: 8px; 
  }
  
  .conducteur1-info {
    margin: 0; 
  }

  .service{
    width: 24px; 
    height: 24px; 
  }   

  .servicestrip {
    display: flex;
    align-items: center;
    margin-left: 530px;
    margin-top: -100px;
  }
  .servicetrip {
    margin-left: 8px;
    height: 25px;
    width: 25px;
  }

.pasderesultat{
  color: #130C4D;
}
  
.labelmestrajets{
    margin-top: 40px;
    font-size: 24px;
    text-align: center;
    color: #0D47A1;
    
  }

  .services-triplist{
    display: flex;
    align-items: center;
    margin-left: 580px;
    margin-top: -40px;
  } 

  .iconetwo{
 height: 30px;
 width: 30px;
  } 

  .noresult-container{
   margin-left: 200px;
   margin-top: 150px;

     } 

.text_liste{
      
   font-size: large;
        } 

.texte_passager{
  font-size: large;
       } 