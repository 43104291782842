.home-container {
  position: relative;
  height: calc(100vh - 90Px + 90px); 
}

.images-container {
  position: absolute;
  left: 70px;
  top: 50px;
  margin-top: 150px;
}

.animated-image {
  position: absolute;
  left: 40px;
  top: 0;
  width: 120%;
}

.content-container {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  width: auto;
}

.title {
  margin-top: 20px;
  margin-right: 140px;
  margin-left: 180px;
  font-size: 1em;
}

.search_packages {
  margin-top: 40px;
  margin-left: 980px;
}

.subtitle1 {
  margin-top: 60px;
  margin-left: 1580px;
  font-size: 0.7em;
  transform: scale(1.2);
}

.subtitle2 {
  margin-top: 60px;
  margin-left: 1580px;
  font-size: 0.7em;
  transform: scale(1.2);
}

.boutton {
  background-color: #2196F3;
  position: relative;
  color: #FFFFFF;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  top: 10px;
  right: 0;
  left: 970px;
}

.boutton:hover {
  background-color: #0D47A1;
  transform: scale(1.2);
}

.search-bar {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: 320px;
}

.search-input-container {
  position: relative;
}

.search-input {
  width: 250px;
  height: 50px;
  padding: 5px;
  border: 1px solid #ccc; /* Ajout de la bordure autour des champs */
}

.search-input-divider {
  width: 1px;
  height: 100%;
  background-color: #ccc;
  margin: 0 10px;
}

.search-button {
  background-color: #2196F3;
  color: #FFFFFF;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 30px;
  margin-top: -10px;
}

.search-button:hover {
  background-color: #0D47A1;
  transform: scale(1.2);
}


.search-input::placeholder {
  font-weight: bold;
}

.with-icon1, .with-icon2  {
  background-image: url('./cercle.png'); 
  background-repeat: no-repeat;
  background-position: left 10px center; 
  padding-left: 30px; 
  background-size: 30px;
}

.with-icon3 {
  background-image: url('./calendrier.png'); 
  background-repeat: no-repeat;
  background-position: left 10px center; 
  padding-left: 30px; 
  background-size: 30px;
}


.with-icon4 {
  background-image: url('./utilisateur-sexe-neutre.png'); 
  background-repeat: no-repeat;
  background-position: left 10px center; 
  padding-left: 30px; 
  background-size: 30px;

}

/* Media Queries */


.search-input.replacement-text {
  display: none;
  /* Ajoutez les styles souhaités pour le texte de remplacement sur les petits écrans */
}


@media (max-width: 767px) {
  .home-container {
    overflow-y: auto; /* Désactiver le défilement vertical sur les petits écrans */
    overflow-x: hidden;
  }

  .replacement-text {
    display: block;
    /* Ajoutez les styles souhaités pour le texte de remplacement sur les petits écrans */
  }
  
  .search-input {
    display: none;
  }
  
}

.error-message {
  color: red;
  font-size: 15px;
  margin-top: -5px;
}
