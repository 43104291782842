
 .date2{
  font-size: 26px;
  color:#130C4D;
  margin-top: 30px;

}


.deparv-container2{
   
  font-size: 20px;
  color: #130C4D;
  margin-right: 360px;
  margin-top: 50px;
}  


.detailstrajet-distance{

  margin-right: 30px;
  color: #306bc5;
}

/*.dtadr2-label2 {
  color:#130C4D ; 
  margin-left: 590px;
}
*/
/*.detailstrajet-hourdepart{
    
  margin-left: 250px;
  margin-top: -100px;
}  */  

.row {
  display: flex;
}

.row > div {
  margin-right: 0px; 
}


.adrhr,
.adrde2,
.adrarv2 {
  flex-direction: column;
  margin-left: 800px;
  
}


.dot-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bloc2{
  margin-top: -100px;
}

.dot {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  margin: 5px 0;
}
   

.details-horizontal-line1 {
  width: 600px; 
  border: none;
  border-top: 2.5px solid cornflowerblue; 
  margin: 10px 0; 
  margin-left: 650px;
  margin-top: 50px;
}

.services-detailstrajet {
  display: flexs;
  align-items: center;
  margin-left: 730px;
  margin-top: 40px;
}

.service-detailstrajet{
  width: 24px; 
  height: 24px; 
  margin-left: 10px;
}


.taille_poids{
  margin-left: 260px;
}
