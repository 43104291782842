
.profile-container {
  max-width: 740px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fffefe;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
}

.profile-info {
  text-align: center;
}

.profile-name {
  font-size: 24px;
  font-weight: bold;
  margin-top: 50px;
  color: #0056b3;
}

.user-email,
.user-phone {
  font-size: 16px;
  margin-bottom: 5px;
  color: #0056b3;
}

.star {
  width: 20px;
  height: 20px;
}

.profile-image-container {
  position: relative;
  display: inline-block;
  margin-left: 200px;
}

.profile-image {
  width: 120px;
  height: 140px;
  border-radius: 50%;
  margin-left: 420px;
  margin-top: -290px;
}

.camera-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.phorizontal-line {
  border: none;
  border-top: 1px solid #ddd;
  margin: 10px 0;
}

.upload-button {
  display: inline-block;
  color: #0056b3;
  cursor: pointer;
  border-radius: 4px;
}

.upload-button:hover {
  background-color: #fcfcfc;
}

.side-textarea {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.edit-infos {
  padding: 5px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.edit-infos:hover {
  background-color: #0056b3;
}


.piece-identite-image {
  width: 100%;
  max-height: 300px;
  object-fit: contain;
  margin-bottom: 10px;
}

.remove-piece-identite {
  padding: 5px 10px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.remove-piece-identite:hover {
  background-color: #b02a37;
}


.profile-buttons {
  cursor: pointer;
  margin: 10px 0;
  font-size: 16px;
  font-weight: bold;
  color: #007bff;
}

.profile-buttons:hover {
  text-decoration: underline;
}

.profile-buttons
{
  color: #0056b3;
}


.vehicule-details {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.vehicule-details li {
  font-size: 16px;
  margin-bottom: 5px;
}

.restart {
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.restart:hover {
  background-color: #0056b3;
}


.permis-image {
 height: 200px;
 width: 300px;
}

.profile-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  gap: 10px; 
}

.profile-button {
  display: flex;
  align-items: center; 
  cursor: pointer;
}

.icon {
  margin-right: 10px; 
}

.bio-textarea {
  width: 400px;
  height: 150px;
  resize: vertical;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: 40px;
}

.text-profile0 {
  margin-right: 100px;
  font-size: 16px; 
  color: #0056b3;
}


.text-profile1 {
  margin-top: 30px;
  margin-right: 100px;
  font-size: 16px; 
  color: #130C4D;
}


.text-profile2 {
  margin-top: 30px;
  margin-left: -330px;
  color: #130C4D;
}

.text-profile2 label {
  display: block;
  margin-bottom: 10px;
}

.text-profile2 input[type="checkbox"] {
  margin-right: 5px;
  width: 25px;
  height: 25px;
}

.profile-labe1{
  margin-left: 200px;
  margin-top: 20px;
}

.input1{
  margin-left: 200px;
 
}

.profile-labe2{
  margin-left: 180px;
  margin-top: 20px;
  ;
}

.input2{
  margin-left: 50px;
}

.profile-labe3{
  margin-left: 190px;
  margin-top: 20px;
  ;
}

.input3{
  margin-left: 95px;
}

.profile-labe4{
  margin-left: 170px;
  margin-top: 20px;
  ;
}

.input4{
  margin-left: 20px;
}

.profile-labe5{
  margin-left: 190px;
  margin-top: 20px;
  ;
}

.input5{
  margin-left: 180px;
}

.idf1{
  margin-left: -410px;
}

.user-email{
  margin-left: -360px;
}

.user-phone{
  margin-left: -430px;
}
.user-bio-text{
  width: 400px;
  padding: 10px;
  color: #7e7e7e;
}

