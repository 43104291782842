/* drivinglicence.css */
.drivinglicence-text {
  font-size: 26px;
  color: #130C4D;
  margin-top: 80px;
}

.icone-drivinglicence {
  width: 200px;
  height: auto;
  margin-top: 40px;
  transition: width 0.3s ease;
}



.upload-icon {
  height: 50px;
  width: 50px;
  margin-top: 40px;
}

.upload-label {
  color: #130C4D;
}

#image-upload::-webkit-file-upload-button {
  visibility: hidden;
}

#image-upload::before {
  content: 'Parcourir';
  visibility: visible;
  display: inline-block;
  padding: 5px 10px;
  background-color: blue;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.restart {
  background-color: #2196F3;
  color: #FFFFFF;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 35px;
  margin-right: 50px;
  height: 35px;
  width: 140px;
}

.confirm {
  background-color: #2196F3;
  color: #FFFFFF;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 35px;
  height: 35px;
  width: 140px;
}

/* Augmenter la taille de l'image une fois téléchargée */
.icone-drivinglicence.uploaded {
  width: 400px;
  height: 400px;
}
