/* Add this style to your edit1.css file */
.custom-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    height: 200px;
    width: 400px;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid black;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 9999; /* Set a high z-index value to ensure the dialog box appears on top */
  }
  
  /* Adjust the z-index for the datepicker to be lower than the dialog box */
  .react-datepicker__portal {
    z-index: 9998; /* Set a lower z-index value than the dialog box */
  }
  .horizontal-line-edit{
    width: 300px; 
    border: none;
    border-top: 2.5px solid cornflowerblue; 
    margin: 10px 0; 
    margin-left: 780px;
    margin-top: 5px;
    margin-bottom: 50px;
  }