.label2 {
    font-size: 24px;
    text-align: center;
    color: #0D47A1;
    margin-top: 200px;
  }
  .carteb{
    margin-top: 60px;
  }

.form-container {
    margin-top: 50px;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 5px;
  }
  
  .form-container label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #5a5959
  }
  
  .form-container input,
  .form-container select {
    width: 400px;
    height: 45px;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 15px;
    font-size: 16px;
  }
  .form-container option {
    background-color: #f2f2f2;
    color: blue;
  }
  .cb{
    width: 28px;
    height: 28px;
    margin-left: 0px;
    margin-right: 3px;
  }

  .cb-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  
  .labelcb1{
   
    width: 300px;
    margin-right: 400px;
    margin-top: 40px;
  }

  .div3{
    width: 300px;
    margin-right: 600px;
  }

  .labelcb2{
    font-weight: bold;
  }

  .form-container button {
    background-color: #0d47a1;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .form-container button:hover {
    background-color: #004ba0;
  }
  .form-container .pay-button {
    align-self: flex-end;
  }

  .input-cvv {
    width: 90px;
    height: 45px;
    margin-left: -20px;
    border: 1px solid #ccc;
    border-radius: 15px;
  }

  .pay-button {
    align-self: flex-end;
    margin-left: 140px;
    width: 100px;
    height: 45px;
    background-color: #2196F3;
    color: #FFFFFF;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;

  }