/* Edit6.css */
.edit6-container{
  margin-top: 160px;
}


.edit6-content {
    background-color: #fff;
    padding: 40px; 
    max-width: 500px; 
    max-height: 500px;
    margin-top: 100px;
    margin: 0 auto; 
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

h2 {
    font-size: 24px;
    margin-bottom: 15px;
}

.tarif-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.tarif {
    font-size: 36px;
    margin: 0 20px;
}

button {
    border: none;
    background-color: #3498db;
    color: #fff;
    font-size: 18px;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #2980b9;
}

.confirm-button {
    background-color: #27ae60;
}

.confirm-button:hover {
    background-color: #219652;
}
