.recherche-container {
    position: relative;
    height: calc(100vh - 90px + 90px); 
    margin-bottom: 300px;
  }
  .title1 {
    margin-top: 20px;
    margin-right: 140px;
    margin-left: 180px;
    font-size: 1.2em;
  }
  .search-bar1 {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-left: 320px;
  }
  
  .search-input-container1 {
    position: relative;
  }
  
  .search-input {
    width: 250px;
    height: 50px;
    padding: 5px;
    border: 1px solid #ccc; 
  }
  
  .search-input-divider {
    width: 1px;
    height: 100%;
    background-color: #ccc;
    margin: 0 10px;
  }
  
  .search-button1 {
    background-color: #2196F3;
    color: #FFFFFF;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 30px;
    margin-top: -10px;
  }
  
  .search-button1:hover {
    background-color: #0D47A1;
    transform: scale(1.2);
  }
  
  
  .search-input1::placeholder {
    font-weight: bold;
  }
  
  .with-icons1, .with-icons2  {
    background-image: url('../Public/cercle.png'); 
    background-repeat: no-repeat;
    background-position: left 10px center; 
    padding-left: 30px; 
    background-size: 30px;
  }
  
  .with-icons3 {
    background-image: url('../Public/calendrier.png'); 
    background-repeat: no-repeat;
    background-position: left 10px center; 
    padding-left: 30px; 
    background-size: 30px;
  }
  
  
  .with-icons4 {
    background-image: url('../Public/utilisateur-sexe-neutre.png'); 
    background-repeat: no-repeat;
    background-position: left 10px center; 
    padding-left: 30px; 
    background-size: 30px;
  
  }


.ligne {
    border-top: 3.5px solid #ccc;
    height: 1px;
    width: 400px;
    border-radius: 8px;
    margin-right: 0px;
    margin-left: 290px;
    margin-top: 10px;
  }
    
  
.trie1 {
    display: flex;
    color: #130C4D;
    align-items: center;
    font-size: 1.5em;
    margin-top: 50px;
    margin-left: 400px;
    margin-bottom: 10px;
    white-space: nowrap;
   
}

.section1 {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-left: 360px;
    size:20px ;    

  }

.icone1 {
    width: 24px;
    height: 24px;
    margin-right: 20px;
  }
  
  .filtre1 {
    margin-right: 10px;
    white-space: nowrap;
    color: #130C4D;
  
  }
  
  .checkbox1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 20px;
    border: 1px solid #ccc;
    overflow: hidden;
    margin-left: 55px;
    margin-top: 15px;
  }
  
  .checkbox1 label {
    display: block;
    width: 18px;
    height: 18px;
  }
  
  .checkbox1 label::after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #ccc;
    transition: background-color 0.3s ease;
  }
  
  .checkbox1 input[type="checkbox"]:checked + label::after {
    background-color: red;
  }

  .section2 {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-left: 0px;
    
  }

.icone2 {
    width: 24px;
    height: 24px;
    margin-right: 20px;
  }
  
  .filtre2 {
    margin-right: 10px;
    white-space: nowrap;
    color: #130C4D;
    font-size: 2em;
  }
  
  .checkbox2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 20px;
    border: 1px solid #ccc;
    overflow: hidden;
    margin-left: 65px;
    margin-top: 15px;
  }
  
  .checkbox2 label {
    display: block;
    width: 18px;
    height: 18px;
  }
  
  .checkbox2 label::after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #ccc;
    transition: background-color 0.3s ease;
  }
  
  .checkbox2 input[type="checkbox"]:checked + label::after {
    background-color: red;
  }


  .section3 {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-left: 340px;
  }

.icone3 {
    width: 24px;
    height: 24px;
    margin-right: 20px;
  }
  
  .filtre3 {
    margin-right: 10px;
    white-space: nowrap;
    color: #130C4D;
  }
  
  .checkbox3 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 20px;
    border: 1px solid #ccc;
    overflow: hidden;
    margin-left: 117px;
    margin-top: 15px;
  }
  
  .checkbox3 label {
    display: block;
    width: 18px;
    height: 18px;
  }
  
  .checkbox3 label::after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #ccc;
    transition: background-color 0.3s ease;
  }
  
  .checkbox3 input[type="checkbox"]:checked + label::after {
    background-color: red;
  }

  .trie2 {
    display: flex;
    color: #130C4D;
    align-items: center;
    font-size: 1.5em;
    margin-top: 30px;
    margin-left: 300px;
    white-space: nowrap;
   
}
  
  .section4 {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-left: 440px;
  }

.icone4 {
    width: 24px;
    height: 24px;
    margin-right: 20px;
  }
  
  .filtre4 {
    margin-right: 10px;
    white-space: nowrap;
    color: #130C4D;
  }
  
  .checkbox4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 20px;
    border: 1px solid #ccc;
    overflow: hidden;
    margin-left: 70px;
    margin-top: 15px;
  }
  
  .checkbox4 label {
    display: block;
    width: 18px;
    height: 18px;
  }
  
  .checkbox4 label::after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #ccc;
    transition: background-color 0.3s ease;
  }
  
  .checkbox4 input[type="checkbox"]:checked + label::after {
    background-color: red;
  }

  .section5 {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-left: 340px;
  }

.icone5 {
    width: 24px;
    height: 24px;
    margin-right: 20px;
  }
  
  .filtre5 {
    margin-right: 10px;
    white-space: nowrap;
    color: #130C4D;
  }
  
  .checkbox5 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 20px;
    border: 1px solid #ccc;
    overflow: hidden;
    margin-left: 25px;
    margin-top: 15px;
  }
  
  .checkbox5 label {
    display: block;
    width: 18px;
    height: 18px;
  }
  
  .checkbox5 label::after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #ccc;
    transition: background-color 0.3s ease;
  }
  
  .checkbox5 input[type="checkbox"]:checked + label::after {
    background-color: red;
  }
    
  .section6 {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-left: 260px;
  }

.icone6 {
    width: 24px;
    height: 24px;
    margin-right: 20px;
  }
  
  .filtre6 {
    margin-right: 10px;
    white-space: nowrap;
    color: #130C4D;
  }
  
  .checkbox6 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 20px;
    border: 1px solid #ccc;
    overflow: hidden;
    margin-left: 94px;
    margin-top: 15px;
  }
  
  .checkbox6 label {
    display: block;
    width: 18px;
    height: 18px;
  }
  
  .checkbox6 label::after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #ccc;
    transition: background-color 0.3s ease;
  }
  
  .checkbox6 input[type="checkbox"]:checked + label::after {
    background-color: red;
  }

  .section7 {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-left: 260px;
  }

.icone7 {
    width: 24px;
    height: 24px;
    margin-right: 20px;
  }
  
  .filtre7 {
    margin-right: 10px;
    white-space: nowrap;
    color: #130C4D;
  }
  
  .checkbox7 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 20px;
    border: 1px solid #ccc;
    overflow: hidden;
    margin-left: 80px;
    margin-top: 15px;
  }
  
  .checkbox7 label {
    display: block;
    width: 18px;
    height: 18px;
  }
  
  .checkbox7 label::after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #ccc;
    transition: background-color 0.3s ease;
  }
  
  .checkbox7 input[type="checkbox"]:checked + label::after {
    background-color: red;
  }


  .section8 {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-left: 360px;
  }

.icone8 {
    width: 24px;
    height: 24px;
    margin-right: 20px;
  }
  
  .filtre8 {
    margin-right: 10px;
    white-space: nowrap;
    color: #130C4D;
  }
  
  .checkbox8 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 20px;
    border: 1px solid #ccc;
    overflow: hidden;
    margin-left: 80px;
    margin-top: 15px;
  }
  
  .checkbox8 label {
    display: block;
    width: 18px;
    height: 18px;
  }
  
  .checkbox8 label::after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #ccc;
    transition: background-color 0.3s ease;
  }
  
  .checkbox8 input[type="checkbox"]:checked + label::after {
    background-color: red;
  }

  .ligne-verticale {
    width: 3px;
    height: 600px;
    background-color: #ccc;
    margin-left: 700px;
    margin-top: -600px;
  }
  
  
   .resultat {
    display: flex;
    color: #130C4D;
    align-items: center;
    font-size: 1.5em;
    margin-top: -600px;
    margin-left: 750px;
    margin-bottom: 10px;
    white-space: nowrap;
   }

   .triangle-container {
    width: 450px;
    height: 150px;
    background-color: #FFFFFF;
    border: 1px solid #ccc;
    border-radius: 12px;
    margin-bottom: 10px;
    margin-left: 900px;
  }

  .triangle-content {
    margin-left: -200px;
  }
  
  .deparr{    
    padding: 10px;
    margin-left: -130px;
    color: #130C4D;
    font-size: 18px;
  }

  .prix{    
    padding: 10px;
    margin-left: 560px;
    color: #130C4D;
    margin-top: -38px;
    font-size: 18px;
  }



  .conducteur {
    display: flex;
    align-items: center;
    margin-left: 240px;
    color: #130C4D;
    margin-top: 58px;
  }
  
  .profil {
    width: 24px; 
    height: 24px; 
    margin-right: 8px; 
  }
  
  .conducteur-info {
    margin: 0; 
  }

  .service{
    width: 24px; 
    height: 24px; 
  }   

  .services {
    display: flex;
    align-items: center;
    margin-left: 530px;
    margin-top: -20px;
  }
  .service {
    margin-left: 8px;
  }

.success-message{
 
  color: green;
  }
  .pasderesultat{
    color: #130C4D;
  }
    
  .service-search2{
    width: 24px; 
    height: 24px; 
    margin-left: 10px;
  }   

  .services-search2 {
    display: flex;
    align-items: center;
    margin-left: 630px;
    margin-top: -40px;
  } 