

  .messagerie-footer input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    margin-top: 300px;
  }
  
  .messagerie-footer button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 300px;
  }
  
  .framed-container {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 500px; /* Adjust the width as needed */
    margin: 0 auto;
    background-color: #fff;
    margin-top: 80px;
}

.messagerie-header {
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.messagerie-footer {
    display: flex;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #ccc;
}
/* Add styles for the message content and time */
.message-content {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.message-text {
  flex: 1;
  margin-right: 10px;
}

.message-time {
  font-size: 12px;
  color: #999;
  text-align: right; /* Align the time to the right */
  min-width: 60px; /* Adjust as needed to ensure proper spacing */
}
