header{
      border-bottom: solid 2px rgb(184, 180, 180) ;
      height: 90px;

}

ul{

    list-style: none;
    display: flex;
    justify-content: flex-end;
}

ul li {
    margin-left: 40px;
}

a{
    text-decoration: none;
    color: #2196F3;
    line-height: 5.1;
}

.pls{
  height: 20px;
  width: 20px;
 }

.Header img {
    width: 140px; 
    height: 65px; 
    margin-left: 0;
  }
  
  .Header img:hover {
    transform: scale(1.2);
  }

  .Header li:first-child {
    margin-right: auto;
    margin-top: 0px;
    margin-left: 240px; 
  }


.Header button {
 
  background-color: #FFFFFF;
  color: #2196F3;
  border: 2px solid #2196F3;
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  max-width: 600px;
}

.Header button:hover {
  background-color: #2196F3;
  color: #FFFFFF;
  transform: scale(1.2);
}

.Header button.blue {

  background-color: #2196F3;
  color: #FFFFFF;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 120px;
  max-width: 600px;
}

.pubtrajet {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.pubtrajet .icone {
  width: 30px; 
  height: 30px;
  margin-right: 10px;
  vertical-align: middle;
}

.Header button.blue:hover {
  background-color: #2196F3;
  transform: scale(1.2);
  max-width: 600px;
 
}
.Header ul li.mesreservations,
.Header ul li.mestrajets {
  color: #158beb;
  position: relative;
  z-index: 1;
  font-size: 14.5;
  margin-top: 30px;
  margin-right: 30px;
  max-width: 600px;
}

.Header ul li.mestrajets {
  margin-left: -10px;
}
.Header button.Ajout {
  background-color: #2196F3;
  color: #FFFFFF;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 14.5px;
  margin-right: 40px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  max-width: 600px;
}

.Header button.toprofil{
  background-color: #FFFFFF;
  color: #2196F3;
  border: 2px solid #2196F3;
  margin-top: 20px;
  margin-right: 60px;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 14.5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  max-width: 600px;
}
.mobile-icons {
  display: none;
}

@media only screen and (max-width: 767px) {
  .Header ul li.mesreservations,
  .Header ul li.mestrajets,
  .Header button.Ajout,
  .Header button.blue,
  .Header button,
  .Header button.toprofil {
    display: none;
  }
  .Header ul li.commentcamarche {
    display: none;
  }
  .Header ul li.image {
    text-align: center;
  }
  .Header ul li.image img {
    display: block;
    margin: 0 auto;
  }
  
}


/* Style du menu déroulant lui-même */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 200px; /* Ajuster la largeur du dropdown selon vos besoins */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Style des liens dans le menu déroulant */
.dropdown-content a {
  color: #333333;
  padding: 2px 10px; /* Ajuster les valeurs de padding pour réduire la distance entre les éléments */
  text-decoration: none;
  display: block;
  text-align: center;
}

/* Mettre les liens au centre */
.dropdown-content a {
  display: flex;
  justify-content: center;
}

/* Changer la couleur du lien au survol */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Afficher le menu déroulant au survol */
.dropdown:hover .dropdown-content {
  display: block;
}
