*{
  margin: 0;
  box-sizing: border-box;
  height: auto;

}


.images-container {
  position: absolute;
  left: 70px;
  top: 50px;
  margin-top: 150px;
}

.animated-image {
  position: absolute;
  left: 40px;
  top: 0;
  width: 120%;
}


h1{

    font-size: 20px;
    color: #130C4D;
    position: center;
    margin-top: 30px;
    margin-bottom: 50px;
}

input{
    border: none;
    background-color: rgb(229, 226green, 226blue);
    height: 4em;
    width: 80%;
    border-radius: 8px;
    text-align: center;
    padding: 2em;
    margin-bottom: 10px;
 }
 
 input :hover{
    transform: scale(1.2);
 }

 input:focus{
 
     outline-color:#2196F3 ;
 }
.page{

    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.title1 {
  margin-top: 20px;
  margin-right: 140px;
  margin-left: 180px;
  font-size: 1.5em;
}
.cover{

    background-color: rgb(230, 231, 231);
    width: 25em;
    height: 28em;
    position: relative;
    border-radius: 1em;
    box-shadow: 0 0.080em 0.800em rgb(100, 156green, 156blue);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    display: flex;
    margin-left: auto; 
    margin-right: 12em; 
    margin-top: 8em;
}

.alt-login{

    width: 80%;
    height: 3em;
    display: flex;
    justify-content: space-around;
    cursor: pointer;
    transition: 0.5s;
}

.alt-login div{

   width: 45%;
   height: 90%;
   border-radius:0.25em;
   background-color:  rgb(230, 231, 231);
   background-position: center;
   background-size: contain;
   background-repeat: no-repeat;
   cursor: pointer;
   margin-top: -15px;
}

.google{

    background-image: url("google.png");
}

.facebook{
    background-image: url("facebook.png");
}

.facebook:hover{
    transform: scale(1.2);
}

.google:hover{
    transform: scale(1.2);
}


.login-btn{

    background-color: #2196F3;
    color: #FFFFFF;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 35px;

}

.text {
    position: relative;
    margin-bottom: 60px;
    color: #130C4D;
  }
  
.login-btn:hover{

    background-color: #0D47A1;
    transform: scale(1.2);
}

.text{
    position: relative;
    bottom: -2em;
}

.error-message {
    color: red;
  }
  

  .forgot-password {
    color: #130C4D; 
    cursor: pointer;
  }
  
 .forgot-password:hover {
    text-decoration: underline;
  }
  

  .password-input {
    position: relative;
    width: 400px;
    left: 13px;
  }
  
  .password-input input[type="password"] {
    padding-right: 0px; 
  }
  
  .password-input .eye-icon {
    position: relative;
    top: 50%;
    right: 50px; 
    transform: translateY(-50%);
    cursor: pointer;
    background-image: url('./eye.png'); 
    background-repeat: no-repeat;
    background-position: center;
    background-size: 18px; 
  }
  
  .password-input .eye-icon.masquer {
    background-image: url('./eye.png'); 
  }
  