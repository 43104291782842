

.datehour-container1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
   
  }
  
 
  .datehour-label1{
    margin-top: 5px;
    font-size: 24px;
    text-align: center;
    color: #0D47A1;
    
  }
  .label3 {
    margin-top: 330px;
    font-size: 24px;
    text-align: center;
    color: #0D47A1;
    
  }
  .heuree {
    margin-left: -870px;
    background-color: #e2e2e2;
    width: 200px;
    height: 40px;
    font-size: 16px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-top: -100px;
    margin-left: 35px;
  }

  .tocontinuate1 {
    background-color: #2196F3;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
    margin-left: 40px;
  } 
  .tocontinuate1:hover {
    background-color: #1565c0;
  } 

  .horizontal-line6{
    width: 300px; 
    border: none;
    border-top: 2.5px solid cornflowerblue; 
    margin: 10px 0; 
    margin-left: 30px;
    margin-top: -15px;
  }