.submit{
    background-color: #2196F3;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 200px;
    margin-bottom: 300px;
  }
  
  .submit:hover {
    background-color: #1565c0;
  } 

  
  .unselected-route {
    color: rgb(116, 115, 115); /* Couleur grise pour les itinéraires non sélectionnés */
  }
  
  .comeback4{
    width: 40px;
    height: 40px;
    color: cornflowerblue;
    margin-top: 20px;
    margin-left: -1460px;

  }