.etape4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 350px;
  }
  
  .labeletape4 {
    font-size: 24px;
    text-align: center;
    color: #0D47A1;
    margin-top: 5px;
  }

  .route-cities-container {
    display: flex;
    flex-direction: column;
    color: #130C4D;
    margin-top: 60px;
  }
  
  .route-city-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 10px;
  }
  
  .checkbox-btn {
    width: 16px;
    height: 16px;
    margin-left: 350px;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .button-container button {
    margin: 5px;
  }
  

  .add-escale-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .add-escale-text {
    cursor: pointer;
  }
  
  .affichage-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
  }
  
  .affichage {
    empty-cells: show;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    margin-top: -35px;
    transition: background-color 0.3s ease;
  }
  
  .icon {
    vertical-align: middle;
    margin-left: 5px;
  }
  
  .add-escale-text:hover {
    color: #2196F3;; 
  }

  .escale-btn {
    background-color: #2196F3;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
  }

  .escale-btn:hover {
    background-color: #1565c0;
  } 

  .tocontinuate {
    background-color: #2196F3;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
  } 
  .tocontinuate:hover {
    background-color: #1565c0;
  } 

  .comeback5{
    width: 40px;
    height: 40px;
    color: cornflowerblue;
    margin-top: 20px;
    margin-left: -1460px;

  }