.date1{
    font-size: 26px;
    color:#130C4D;
    margin-top: 30px;
  
}

.deparv-container{
   
    font-size: 20px;
    color: #130C4D;
    margin-right: 360px;
    margin-top: 20px;
}  

.trajetdetails-distance{

  margin-right: 30px;
  color: #306bc5;
}


.dot-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .dot {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    margin: 5px 0;
  }
  

  .horizontal-line {
    width: 600px; 
    border: none;
    border-top: 2.5px solid cornflowerblue; 
    margin: 10px 0; 
    margin-left: 650px;
    margin-top: 50px;
  }

  .tdadr-label2 {
    color:#130C4D ; 
   
  }
  
  .adrdep-adr2 {
    color: #306bc5; 
   
  }

  .tdadr2-label2 {
    color:#130C4D ; 
  
  }
  
  .adrarv-adr2 {
    color: #306bc5; 
  }

  .bloc3{
    margin-top: -100px;
  }

  .row {
    display: flex;
  }
  
  .row > div {
    margin-right: 0px; 
  }
  
  .adrhr3,
  .adrdep2,
  .adrarv2 {
    flex-direction: column;
    margin-left: 800px;
    
  }
  
  /*.adrdep2{
    margin-left: 240px;
  } 

  .adrarv{
    margin-left: 210px;
  } */
  
  .price{
    margin-left: 210px;
    margin-top: 15px;
    color: darkgray;
  } 
  .price1{
    margin-left: 910px;
    margin-top: -30px;
    color: black;
  } 
  .horizontal-line2 {
    width: 600px; 
    border: none;
    border-top: 2.5px solid cornflowerblue; 
    margin: 10px 0; 
    margin-left: 650px;
    margin-top: 100px;
  }

  .horizontal-line3 {
    width: 600px; 
    border: none;
    border-top: 2.5px solid cornflowerblue; 
    margin: 10px 0; 
    margin-left: 650px;
    margin-top: 15px;
  }

  .icons-profil{
    display: inline-block;
    margin-top: 10px;
    margin-left: 900px;
  }
  

  .iconprof {
    width: 37px;
    height: 37px;
    margin-right: 30px;
  }
  
  .icongoprofil{
    width: 37px;
    height: 37px;
   
  }

  .conduct{
   margin-top: -35px;
   margin-left: -800px;
    
  }

  .verification{
    margin-top: 25px;
    margin-left: -880px;
    height: 35px;
    width: 35px; 
   }
   .cond{
    font-size: 17px;
    margin-top: -35px;
    margin-left: -740px;
     
   }
   .horizontal-line4 {
    width: 600px; 
    border: none;
    border-top: 2.5px solid cornflowerblue; 
    margin: 10px 0; 
    margin-left: 650px;
    margin-top: 15px;
  }
 
  .tocontact{
    width: 37px;
    height: 37px;
    margin-left: 30px;
    margin-top: 15px;
    margin-right: 5px;
  }

  .cont{
    font-size: 17px;
    margin-top: -35px;
    margin-left: 290px;
     
   }

   .horizontal-line5 {
    width: 600px; 
    border: none;
    border-top: 2.5px solid cornflowerblue; 
    margin: 10px 0; 
    margin-left: 650px;
    margin-top: 15px;
  }

  .continuer{

    background-color: #2196F3;
    color: #FFFFFF;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 35px;
    margin-left: 900px;
    height: 40px;
    width: 100px;

}

.departure-label2 {
  color:#130C4D ; 
  margin-left: 10px;
}

.departure-time2 {
  color: #306bc5; 
}

.services-trajetdetails {
  display: flex;
  align-items: center;
  margin-left: 1030px;
  margin-top: 40px;
}

.service-trajetdetails{
  width: 24px; 
  height: 24px; 
  margin-left: 10px;
}   