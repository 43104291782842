.trips {
    margin-top: 40px;
    margin-right: 140px;
    margin-left: 1200px;
    font-size: 0.8em;
  }

  .mostpopular{
    display: flex;
    margin-left: 1200px;
    
  }
  
 

  .first {
    margin-right: 10px;
    
  }
  .first h1 {
    margin-bottom: 5px;
    text-decoration: underline;
  }
  
  .second h1 {
    margin-bottom: 5px;
    text-decoration: underline;
  }
  
  .error-message {
    color: red;
    font-size: 15px;
    margin-top: -5px;
  }
  /* Ajoutez ces styles à votre fichier accueil.css ou un autre fichier CSS séparé */

/* Ajoutez ces styles pour agrandir le dialogue et ajouter un bouton d'annulation */

/* Styles pour le conteneur du dialogue */
.dialog-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Fond semi-transparent pour l'arrière-plan sombre */
}

.select-gender{
  width: 200px;
  height: 55px;
  margin-right: 10px;
}

.ville{
  margin-right: 20px;
}

.adresse-user{
  width: 410px;
  margin-right: 10px;

}

/* Styles pour le contenu du dialogue */
.dialog {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 700px; /* Augmentez la largeur */
  height: 500px;
}

/* Styles pour le titre du dialogue */
.dialog h2 {
  margin: 0 0 10px;
}

/* Styles pour le formulaire du dialogue */
.dialog form {
  display: flex;
  flex-direction: column;
}

/* Styles pour les champs du formulaire */
.dialog input {
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Styles pour les boutons du formulaire */
.dialog button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

/* Styles pour le bouton du formulaire au survol */
.dialog button:hover {
  background-color: #0056b3;
}

/* Styles pour le bouton d'annulation */
.dialog .cancel-button {
  background-color: #ccc;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

/* Styles pour le bouton d'annulation au survol */
.dialog .cancel-button:hover {
  background-color: #999;
}

.send_package{
 
  margin-left: 1050px;
}