.step7-date{
    font-size: 26px;
    color:#130C4D;
    margin-top: 30px;
}

.depart-arrivee1{
   
    font-size: 20px;
    color: #130C4D;
    margin-right: 110px;
    margin-top: 40px;
}  

.dot-container1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 350px;
  }

  .distance{
    margin-right: 350px;
    color: #306bc5;
  }

  
  .dot {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    margin: 5px 0;
  }
  
    
  .iconetrajet{
    margin-right: 340px;
  }

     
  .from{
    margin-right: 340px;
  }

  .to{
    margin-right: 340px;
  }

  .escals{
    margin-right: -195px;
    margin-top: 3px;
  }
  .nb_passager1{
    margin-left: 80px;
    margin-top: 3px;
  } 

  .departure-label {
    color:#130C4D ; 
  }
  
  .departure-time {
    color: #306bc5; 
  }
  
  .step7-label1 {
    color:#130C4D ; 
    margin-left: 250px;
  }
  
  .step7-adr1 {
    color: #306bc5; 
  }

  .step7-label2 {
    color:#130C4D ; 
    margin-left: 180px;
  }
  
  .step7-adr2 {
    color: #306bc5; 
  }

  .escals-label {
    color:#130C4D ; 
    margin-left: -190px;
  }
  
  .affichage-escals {
    color: #306bc5; 
  }

  .nbpassager-label {
    color:#130C4D ; 
  }
  
  .nbpassager-adr {
    color: #306bc5; 
  }

  .heuredepart1{
    margin-left: 0px;
    margin-top: -120px;
  }    

  .horizontal-line {
    width: 600px; 
    border: none;
    border-top: 2.5px solid cornflowerblue; 
    margin: 10px 0; 
    margin-left: 650px;
    margin-top: 50px;
  }

  .adrdep1{
    margin-left: 238px;
    margin-top: 3px;
  } 

  .adrarv1{
    margin-left: 185px;
    margin-top: 3px;
  } 

  .prixx1{
    margin-left: -130px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: rgb(12, 17, 90);
  } 
  .prxx1{
    margin-left: 610px;
    margin-top: 10px;
    color: black;
  } 

  .service1{
    margin-left: -140px;
    margin-top: 15px;
    color: #130C4D;
  } 

  .horizontal-linee2 {
    width: 600px; 
    border: none;
    border-top: 2.5px solid cornflowerblue; 
    margin: 10px 0; 
    margin-left: 650px;
    margin-top: 50px;
  }

  .horizontal-line3 {
    width: 600px; 
    border: none;
    border-top: 2.5px solid cornflowerblue; 
    margin: 10px 0; 
    margin-left: 650px;
    margin-top: 15px;
  }

  .imagess{
    display: inline-block;
    margin-top: 10px;
    margin-left: 900px;
  }
  

  .iconprof {
    width: 37px;
    height: 37px;
    margin-right: 30px;
  }
  
  .iconversprof{
    width: 37px;
    height: 37px;
   
  }

  .conduct{
   margin-top: -35px;
   margin-left: -800px;
    
  }

  .verification{
    margin-top: 25px;
    margin-left: -880px;
    height: 35px;
    width: 35px; 
   }
   .cond{
    font-size: 17px;
    margin-top: -35px;
    margin-left: -740px;
     
   }
   .horizontal-linee4 {
    width: 600px; 
    border: none;
    border-top: 2.5px solid cornflowerblue; 
    margin: 10px 0; 
    margin-left: 650px;
    margin-top: 50px;
  }
 
  .contact{
    width: 37px;
    height: 37px;
    margin-left: 30px;
    margin-top: 15px;
  }
  .servicee{
    width: 24px; 
    height: 24px; 
  }   

  .servicees {
    display: flex;
    align-items: center;
    margin-left: 1130px;
    margin-top: -20px;
  }
  .servicee1 {
    margin-left: 8px;
    height: 25px;
    width: 25px;
  }

 
   .horizontal-line5 {
    width: 600px; 
    border: none;
    border-top: 2.5px solid cornflowerblue; 
    margin: 10px 0; 
    margin-left: 650px;
    margin-top: 15px;
  }

  .continuer{

    background-color: #2196F3;
    color: #FFFFFF;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 35px;
    margin-left: 900px;
    height: 40px;
    width: 100px;

}

.custom-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.dialog-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialog-content h6 {
  margin-top: 10px;
}

.dialog-content button {
  margin-top: 20px;
}

