.container {
    text-align: center;
    margin-top: 170px; /* Ajustez cette valeur selon vos besoins */
  }
  
  .nocar {
    font-size: 18px; /* Ajustez cette valeur selon vos besoins */
    margin-bottom: 10px; /* Ajustez cette valeur selon vos besoins */
  }
  
  .centered-image {
    display: block;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 100%;
    height: 150px;
    width: 150px;

  }
  