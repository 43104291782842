.profiluser {
  position: relative;
  height: auto; 
  overflow-y: auto;
  height: calc(100vh - 90Px + 150px); 
  min-height: 135vh;
  flex-direction: column;

}

.background-image {
  width: 700px;
  height: 350px;
  position: relative;
  z-index: 1;
  margin-top: 40px;
  cursor: pointer;
  align-items: center;
  transition: 0.5s;
  object-fit: cover;
}

.background-image:hover{
  transform: scale(1.2);
}

.profil-image {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 10px;
  margin-top: -50px;
  margin-left: 870px;
  z-index: 2;
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;
  transition: 0.5s;
  object-fit: cover;
  overflow: hidden;
  border-radius: 50%;
}

.profil-image:hover{
  transform: scale(1.2);
}


.deconnexion {
position: absolute;
background-color:#28a745;
color: #FFFFFF;
border: none;
padding: 10px 20px;
border-radius: 8px;
font-size: 16px;
padding: 0;
width: 150px;
height: 50px;
border: none;
cursor: pointer;
align-items: center;
justify-content: center;
transition: 0.3s;
margin-left: 1500px;
margin-top: -1099px;

}

.deconnexion:hover {
  background-color: #28a745;
  }

h1.idf {
 
  margin-top: 30px;

}


.parametres{
transform: scale(1.2); 
}


.rectangle {
height: 100px;
width: 400px;
background-color: white;
border: 3px solid #130C4D;
border-radius: 8px;
position: absolute;
margin-top: -10px;
margin-left: 735px;
display: flex;
align-items: center;
justify-content: center;
}

.params-image {
position: absolute;
transform: translate(0, -50%);
width: 35px;
height: 35px;
margin-left: 650px;
margin-top: -70px;
cursor: pointer;
justify-content: center;
display: flex;
align-items: center;
transition: 0.5s;
}

.textarea-container {
position: absolute;
width: 99%;
height: 99%;
margin-bottom: 0px;
margin-left: 0px;
}

.text-input {
width: 390px;
height: 95px;
padding: 8px;
border: 1px solid #ccc;
border-radius: 4px;

}

.save-button {
position: absolute;
bottom: 8px;
right: 8px;
padding: 0;
width: 30px;
height: 30px;
background-color: transparent;
border: none;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
transition: 0.3s;
}

.save-button:hover {
background-color: #28a745;
}

.save-icon {
width: 100%;
height: 100%;
}

.bottom-text {
 color: #130C4D;
  margin-top: 50px; 
  text-align: center; 
  margin-right: 330px;
  white-space: nowrap;
  font-size: 1.2em;

}

.side-textarea {
  margin-top: 100px;
  width: 300px;
  height: 40px;
  padding: 8px;
  background-color: #f2f2f2;
  border: 1px solid white;
  border-radius: 8px;
  margin-top: 8px;
}
.text1 {
  display: flex;
  color: #130C4D;
  align-items: center;
  margin-top: 10px;
  margin-right: 100px;
  margin-bottom: 10px;
  white-space: nowrap;
}

.text1 > input {
  margin-left: 210px;
}

.text2 {
  color: #130C4D;
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-right: 100px;
  margin-bottom: 10px;
  white-space: nowrap;
}
.text2 > input {
  margin-left: 190px;
}

.text3{
  display: flex;
  color: #130C4D;
  align-items: center;
  margin-top: 5px;
  margin-right: 100px;
  margin-bottom: 10px;
  white-space: nowrap;
}

.text3> input {
  margin-left: 100px;
}

.text4{
  display: flex;
  color: #130C4D;
  align-items: center;
  margin-top: 5px;
  margin-right: 100px;
  margin-bottom: 10px;
  white-space: nowrap;
}
.text4> input {
  margin-left: 105px;
}

.text5{
  display: flex;
  color: #130C4D;
  align-items: center;
  margin-top: 5px;
  margin-right: 100px;
  margin-bottom: 10px;
  white-space: nowrap;
}
.text5> input {
  margin-left: 95px;
}


.modification {
  position: relative;
}

.save-modification {
  position: absolute;
  left: 125%;
  background-color: #2196F3;
  color: #FFFFFF;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 35px;
  transform: translateX(-50%);
  margin-top: 30px;
}

.save-modification:hover{

background-color: #2196F3;
transform: scale(1.2); 

}
.error-message {
  margin-top: 20px;
  color: red;
}
