.button-txt {
    font-size: 16px;
    color: #0d47a1;
    margin-right: 10px; 
    width: 700px;
  }
  
  .choise-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center; 
    width: 600px;
    height: 72px;
    padding-left: 20px; 
    margin-top: 20px;
    margin-left: 600px;
    margin-right: 200px;
    border-radius: 13px;
    transition: background-color 0.3s;
    color: #0d47a1;
  }
  
  .choise-button:hover {
    background-color: #f0f0f0;
  }

  .nextstep{
    width: 25px;
    height: 25px;
  
  }