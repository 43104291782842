/* Style général du formulaire */
.forms {
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .colis-texte {
    text-align: center;
    margin-bottom: 20px;
    font-size: 22px;
  }
  
.label-colis1{
  margin-left: -450px;
  display: block;
  margin-right: 10px;
  font-weight: bold;
  color: #0056b3;
  flex: 0 0 150px; 
  }

    
.label-colis2{
  margin-left: -150px;
  display: block;
  margin-right: -480px;
  font-weight: bold;
  color: #0056b3;
  flex: 0 0 150px; 
  }

  .colis-container{
   margin-bottom: 350px;
    }
  
 .inputs,
  .selector {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 16px;
  }
  
  /* Style du bouton de soumission */
  .validate-colis {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 16px;
    padding: 10px 15px;
    cursor: pointer;
    margin-top: 20px;
    width: 200px;
    z-index: 1;
  }
  
  /* Style des options de la liste déroulante */
  select option {
    font-weight: normal;
  }
  
  /* Mise en forme en cas de survol */
  .validate-colis:hover {
    background-color: #0056b3;
  }
  
  /* Mise en forme en cas de focus */
  .inputs:focus,
  .selector:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  
  .error-message {
    color: #ff0000;
    margin-top: 5px;
  }
  footer {
    position: relative; 
  }

  .vertical-align-container {
    display: flex;
    justify-content: center; 
    align-items: center; 
    margin-top: 10px;
}