.label1 {
    font-size: 24px;
    text-align: center;
    color: #0D47A1;
    margin-top: 200px;
  }

.paiement{
  
    margin-top: 120px;

  }
  
  .carte-bancaire-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center; 
    width: 600px;
    height: 72px;
    padding-left: 20px; 
    margin-top: 20px;
    margin-left: 600px;
    border-radius: 13px;
    transition: background-color 0.3s;
  }
  
  .carte-bancaire-button:hover {
    background-color: #f0f0f0;
  }
  
  .button-text {
    font-size: 16px;
    color: #0d47a1;
    margin-right: 10px; 
    width: 400px;
  }
  
  .card-logos {
    display: flex;
    align-items: center; 
  }
  
  .card-logos img {
    width: 25px;
    height: 25px;
    margin-left: -70px; 
    margin-top: 45px;
    transition: filter 0.3s;
  }
  
  .card-logos img:hover {
    filter: brightness(85%);
  }

  .next{
    width: 25px;
    height: 25px;
    margin-left: 400px;
  }

  .next1{
    width: 25px;
    height: 25px;
    margin-left: 460px;
  }

  .horizontal-line8 {
    width: 600px; 
    border: none;
    border-top: 2.5px solid cornflowerblue; 
    margin: 10px 0; 
    margin-left: 600px;
    margin-top: 10px;
  }

  .passager{
    width: 35px;
    height: 35px;
  }

  .colis{
    width: 35px;
    height: 35px;
  }